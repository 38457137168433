<template>
	<div class="flex justify-center items-center h-screen bg-gray-200 px-6">
		<div class="p-12 max-w-lg w-full bg-white shadow-md rounded-lg">
			<div class="flex justify-center items-center">
				<h2 class="text-blue-700 font-normal text-4xl">Inicio de sesión</h2>
			</div>
			<LoginForm class="mt-4"/>
		</div>
	</div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm"

export default {
	name: "LoginView",
	components: {
		LoginForm
	},
}
</script>