<template>
  <Form @submit="login" :validation-schema="schema">
    <div class="relative text-left mb-6">
      <label for="email" class="text-gray-700 text-xl"> Correo electrónico: </label>
      <Field
        type="email"
        name="email"
        v-model="email"
        :class="inputClass"
        autocomplete="email"
        placeholder="luke@jedi.com"
      />
      <ErrorMessage name="email" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
    </div>
    <div class="block mt-3 relative text-left mb-6">
      <label for="password" class="text-gray-700 text-xl"> Contraseña: </label>
      <Field
        type="password"
        name="password"
        v-model="password"
        :class="inputClass"
      />
      <ErrorMessage name="password" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
    </div>
    <div class="flex justify-between items-center mt-4">
        <router-link to="/forgot-password" class="block text-md fontme text-blue-700 hover:underline">
          ¿Olvidaste tu contraseña?
        </router-link>
    </div>

    <div class="mt-6">
      <BaseBtn
        type="submit"
        text="Entrar"
        btnClass="py-3 px-4 text-center bg-blue-700 rounded-md w-full text-white text-xl hover:bg-blue-600" />
    </div>
  </Form>
</template>

<script>
import { getError } from "@/utils/helpers"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string } from "yup"
import BaseBtn from "@/components/BaseBtn"
import AuthService from "@/services/AuthService"
export default {
  name: "LoginView",
  setup() {
    const schema = object({
      email: string().required("El correo es obligatorio.").email("Ingresa un correo válido."),
      password: string().required("La contraseña es un campo obligatorio."),
    })

    return {
      schema,
      inputClass: "block w-full px-3 py-2 placeholder-gray-400 border border-gray-500 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 form-input text-2xl"
    }
  },
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    async login() {
      const payload = {
        email: this.email,
        password: this.password
      }
      try {
        await AuthService.login(payload)
        const authUser = await this.$store.dispatch("auth/getAuthUser")
        if (authUser) {
          this.$store.dispatch("auth/setGuest", { value: "isNotGuest" })
          this.$router.push("/dashboard");
        } else {
          const error = Error(
            "No se pudo recuperar los datos de usuario después del inicio de sesión"
          );
          error.name = "Fetch User"
          throw error
        }
      } catch (error) {
        this.emitter.emit("notification-set", {
          type: 'error',
          title: "Ha ocurrido un error",
          text: getError(error),
          time: 0,
          group: 'notifications'
        })
      }
    },
  },
  components: {
    BaseBtn,
    Field,
    Form,
    ErrorMessage
  },
};
</script>